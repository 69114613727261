import label from './label';
import placeholder from './placeholder';
import helper from './helper';
import size from './size';

export default {
  mixins: [label, placeholder, helper, size],

  props: {
    id: String,
    name: String,

    value: String,
    min: String,
    max: String,

    autocomplete: String,

    icon: String,
    required: Boolean,
    valid: Boolean,
    invalid: Boolean,

    disabled: Boolean,

    isLoading: Boolean,
  },
  model: { prop: 'value', event: 'input' },
  computed: {
    listeners() {
      // eslint-disable-next-line no-unused-vars
      const { input, change, ...listeners } = this.$listeners;
      return listeners;
    },
    labelClass() {
      return Object.assign(
        {},
        {
          'is-danger': this.invalid,
          'is-success': this.valid,
        },
        this.sizeClass
      );
    },
    controlClass() {
      return {
        'has-icons-left': this.icon,
        'has-icons-right': this.valid,
        'is-loading': this.isLoading,
      };
    },
    inputClass() {
      return Object.assign(
        {},
        {
          'is-danger': this.invalid,
          'is-success': this.valid,
        },
        this.sizeClass
      );
    },
    addonClass() {
      return {
        'is-danger': this.invalid,
        'is-success': this.valid,
        'is-outlined': true,
      };
    },
    nameDesc() {
      return this.name + '-desc';
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value);
    },
    change(event) {
      this.$emit('change', event.target.value);
    },
  },
};
