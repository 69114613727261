<template>
  <span v-if="value && value.updated" class="has-text-grey-light">
    {{ $t('update_info_by') }}
    <span class="tooltip" :data-tooltip="value.updated.uid">{{
      value.updated.displayName
    }}</span>
    {{ $t('update_info_on') }}
    <span>{{ value.updated.date | datetime }}</span>
  </span>
</template>

<script>
import datetime from '@/filters/datetime';

export default {
  name: 'UpdateInfo',
  filters: {
    datetime,
  },
  props: {
    value: Object,
  },
};
</script>
