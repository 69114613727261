import Vue from 'vue';
import { DateTime } from 'luxon';

export default function (value) {
  if (!value) return '';
  if (typeof value === 'string') {
    const dt = DateTime.fromISO(value);
    if (dt.isValid) {
      return dt
        .setLocale(Vue.$locale.locale)
        .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
  }
  return value;
}
