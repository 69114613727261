import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { pick } from 'ramda';
export default {
  props: {
    cid: String,
  },
  computed: {
    ...mapState('company', {
      company: 'record',
      cLoading: 'loading',
      cLoadError: 'loadError',
    }),
    ...mapGetters('perms', ['isManager']),
  },
  mounted() {
    this.cLoad();
  },
  methods: {
    ...mapMutations('companiesRecent', { cPush: 'push' }),
    ...mapActions('company', { cSub: 'sub' }),
    async cLoad() {
      try {
        const company = await this.cSub({
          path: ['companies'],
          id: this.cid,
        });
        this.cPush({
          id: this.cid,
          item: pick(['name', 'employeeUids', 'managerUids'], company),
        });
        return company;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  watch: {
    '$route.params.cid'() {
      this.cLoad();
    },
  },
};
