<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <li class="is-active"><a>{{ $t('breadcrumb_company_settings') }}</a></li>
            <router-link tag="li" :to="{ name: 'company-name', params: { cid } }"><a>{{ $t('breadcrumb_company_name') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-tablet is-5-desktop">
            <sqr-input-text
              class="field"
              label="company_name"
              :value="company.name"
              @change="fieldSet({ field: 'name', value: $event })"
              size="large"
            />
            <update-info class="field" :value="company" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

import company from './company';

import SqrInputText from '@/sqrd/SqrInputText';
import CompanyName from '@/components/CompanyName';
import UpdateInfo from '@/components/UpdateInfo';

export default {
  name: 'CompanyName',
  mixins: [company],
  components: { SqrInputText, CompanyName, UpdateInfo },
  async beforeRouteLeave(from, to, next) {
    await this.send({ clear: true });
    next();
  },
  methods: {
    ...mapActions('company', ['fieldSet', 'send']),
  },
};
</script>
